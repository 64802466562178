/*global css*/
* {
  font-family: "微软雅黑", Arial, Helvetica, sans-serif;
  outline-style: none !important;
  -webkit-text-size-adjust: none; /*_behavior:url(/iepng/iepngfix.htc);*/
}
td,
th,
div,
input,
select,
textarea,
li,
ol,
dt,
dd,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 12px;
  color: #333;
}
font,
span {
  font-size: 12px;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
blockquote {
  padding: 0;
  margin: 0;
  outline: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}
li {
  list-style-type: none;
}
img {
  border: none;
}
/*文字大小，颜色，行距定义 fz:font-size；fc:font-color；flh:font-line-height*/
.fz_12px {
  font-size: 12px;
}
.fz_14px {
  font-size: 14px;
}
.fz_16px {
  font-size: 16px;
}
.fz_60px {
  font-size: 60px;
}
.fc_red {
  color: #ff0000;
}
.fc_gory {
  color: #666;
}
.fc_white {
  color: #fff;
}
.fc_yellow {
  color: #ff6600;
}
.flh_150 {
  line-height: 150%;
}
.flh_180 {
  line-height: 180%;
}
/*form*/
.form_input {
  border: 1px solid #ccc;
  color: #333;
  background: url(../images/input_bg.gif) #fff repeat-x;
  height: 20px;
  line-height: 20px;
}
.form_area {
  border: 1px solid #ccc;
  color: #333;
  background: url(../images/input_bg.gif) #fff repeat-x;
  line-height: 180%;
}
.form_button {
  border: none;
  background: #505050;
  color: #fff;
  height: 22px;
  line-height: 22px;
  padding: 0 5px;
  overflow: hidden;
  width: 80px;
}
.form_button_130 {
  width: 130px;
}
.form_focus {
  border-color: red;
}
.form_gory_button {
  border: none;
  background: url(../images/button_grey.gif);
  width: 110px;
  height: 25px;
  line-height: 25px;
  font-weight: bold;
  color: #555;
  text-align: center;
}
select.form_focus {
  background: #ccc;
}
/* padding */
.clear {
  margin: 0px auto;
  clear: both;
  height: 0px;
  font-size: 0px;
  overflow: hidden;
}
.blank3 {
  margin: 0px auto;
  clear: both;
  height: 3px;
  font-size: 1px;
  overflow: hidden;
}
.blank6 {
  margin: 0px auto;
  clear: both;
  height: 6px;
  font-size: 1px;
  overflow: hidden;
}
.blank9 {
  margin: 0px auto;
  clear: both;
  height: 9px;
  font-size: 1px;
  overflow: hidden;
}
.blank12 {
  margin: 0px auto;
  clear: both;
  height: 12px;
  font-size: 1px;
  overflow: hidden;
}
.blank15 {
  margin: 0px auto;
  clear: both;
  height: 15px;
  font-size: 1px;
  overflow: hidden;
}
.blank20 {
  margin: 0px auto;
  clear: both;
  height: 20px;
  font-size: 1px;
  overflow: hidden;
}
.blank25 {
  margin: 0px auto;
  clear: both;
  height: 25px;
  font-size: 1px;
  overflow: hidden;
}
.blank50 {
  margin: 0px auto;
  clear: both;
  height: 50px;
  font-size: 1px;
  overflow: hidden;
}
.blank75 {
  margin: 0px auto;
  clear: both;
  height: 75px;
  font-size: 1px;
  overflow: hidden;
}
.blank100 {
  margin: 0px auto;
  clear: both;
  height: 100px;
  font-size: 1px;
  overflow: hidden;
}
/*a*/
a {
  color: #333;
  text-decoration: none;
}
a:hover {
  color: #ff6600; /*text-decoration:underline;*/
}
a.red {
  color: red;
  text-decoration: none;
}
a.red:hover {
  color: red;
  text-decoration: underline;
}
.clean:after,
.clean:before {
  display: table;
  content: "";
}
.clean:after {
  clear: both;
}
.clean {
  zoom: 1;
}
/*turn page*/
#turn_page {
  text-align: center;
}
#turn_page[first] .page_button:first-child,
#turn_page[last] .page_button:last-child {
  background-color: #fff;
}
#turn_page[first] .page_button:first-child i,
#turn_page[last] .page_button:last-child i {
  color: #4b4b4b;
}
#turn_page .page_button {
  width: 50px;
  height: 50px;
  line-height: 50px;
  background-color: #fff;
  border-radius: 3px;
  margin: 0 5px;
}
#turn_page .page_button i {
  font-size: 16px;
  color: #0078cc;
}
#turn_page .page_button:hover {
  background-color: #0078cc !important;
}
#turn_page .page_button:hover i {
  color: #fff !important;
}
#turn_page .page_item {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  color: #666;
  background-color: #fff;
}
#turn_page .page_item:hover {
  color: #fff;
  background-color: #0078cc;
}
#turn_page .page_item_current {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  color: #fff;
  background-color: #1677ff;
}
#turn_page a,
#turn_page font {
  font-family: Arial;
  display: inline-block;
  border-radius: 3px;
  margin: 0 2.5px;
  vertical-align: middle;
  -webkit-transition: all 300ms ease 0s;
  -moz-transition: all 300ms ease 0s;
  -o-transition: all 300ms ease 0s;
  transition: all 300ms ease 0s;
  content: "";
}
